import { AES, enc, mode, pad } from 'crypto-js';
import { environment } from 'src/environments/environment';

export function getFormattedDate(dateString, format) {
  if (!dateString) return null;

  const regexDDmmYYYY =
    /^(0?[1-9]|[12][0-9]|3[01])[\/\-](0?[1-9]|1[012])[\/\-]\d{4}$/;
  const regexYYYYmmDD = /^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/;

  if (format == 'dd/mm/yyyy') {
    if (dateString.match(regexDDmmYYYY)) return dateString.replaceAll('-', '/');
    if (dateString.match(regexYYYYmmDD)) {
      var dateParts = dateString.split('-');
      return dateParts[2] + '/' + dateParts[1] + '/' + dateParts[0];
    }
    return null;
  }

  if (format == 'yyyy-mm-dd') {
    dateString = dateString.replaceAll('/', '-');

    if (dateString.match(regexYYYYmmDD)) return dateString;
    if (dateString.match(regexDDmmYYYY))
      return dateString.split('-').reverse().join('-');
    return null;
  }
}

export function cleanObject(obj) {
  for (var propName in obj) {
    if (
      obj[propName] === null ||
      obj[propName] === undefined ||
      obj[propName] === ''
    ) {
      delete obj[propName];
    }
  }
  return obj;
}

export function setNullEmptyObject(obj) {
  for (var propName in obj) {
    if (obj[propName] === '') {
      obj[propName] = null;
    }
  }
  return obj;
}

export function justNumbers(control) {
  if (control.value) {
    let lastChar = control.value.substr(control.value.length - 1);
    let pattern = /^\d+$/;
    if (!pattern.test(lastChar)) {
      let cleanString = control.value.substr(0, control.value.length - 1);
      control.patchValue(cleanString);
    }
  }
}

export function justLetters(control) {
  if (control.value) {
    let lastChar = control.value.substr(control.value.length - 1);
    let pattern = /^[a-zA-Z\u00F1\u00D1]+$/;
    if (!pattern.test(lastChar)) {
      let cleanString = control.value.substr(0, control.value.length - 1);
      control.patchValue(cleanString);
    }
  }
}

export function justLettersAndNumbers(control) {
  if (control.value) {
    let lastChar = control.value.substr(control.value.length - 1);
    let patternLetters = /^[a-zA-Z\u00F1\u00D1]+$/;
    let patternNumbers = /^\d+$/;
    if (!patternLetters.test(lastChar) && !patternNumbers.test(lastChar)) {
      let cleanString = control.value.substr(0, control.value.length - 1);
      control.patchValue(cleanString);
    }
  }
}

export function formatDateString(
  date: string | number | Date,
  zeroHour: boolean = false
): string {
  let dateString = null;
  if (date) {
    const dateC = new Date(date);
    if (zeroHour) {
      dateC.setHours(0, 0, 0, 0);
    }
    dateString = dateC.toISOString();
  }

  return dateString;
}

export function encryptData(data: string) {
  let encryptedData: string = ''
  try {
    const secretKey = enc.Utf8.parse(environment.cypherSecretKey); // 16 bytes
    const iv = enc.Utf8.parse(environment.cypherSecretKey); // 16 bytes
    encryptedData = AES.encrypt(data, secretKey, {
      iv: iv,
      mode: mode.CBC,
      padding: pad.Pkcs7
    }).toString();
  } catch (error) {
    console.error(`Error inesperado encriptando url ${encryptedData}`)
  }
  return encryptedData
}