import { HttpClient, HttpHeaders } from "@angular/common/http";
import { ReadVarExpr } from "@angular/compiler";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { AppConstants } from "src/app/constants";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  _baseURL: string;
  public showAuth;
  public autoStart: boolean;
  constructor(private http: HttpClient) {
    this._baseURL = AppConstants.bienestarAuth;
    this.showAuth = true;
  }
  loginUser(form) {
    const url = `${this._baseURL}/auth/user/login`;
    let body = new FormData();
    body.append("email", form.email);
    body.append("password", form.password);
    return this.http
      .post(url, body, {
        headers: new HttpHeaders({}),
      })
      .pipe(map((data) => data));
  }

  registerUser(form): Observable<any> {
    const url = `${this._baseURL}/auth/user/create`;

    let body = new FormData();
    body.append("email", form.email);
    body.append("password", form.password1);
    body.append("password_confirmation", form.password2);
    return this.http
      .post(url, body, {
        headers: new HttpHeaders({}),
      })
      .pipe(map((data) => data));
  }
  registerProvider(body): Observable<any> {
    const url = `${this._baseURL}/auth/provider/create`;
    return this.http
      .post(url, body, {
        headers: new HttpHeaders({}),
      })
      .pipe(map((data) => data));
  }
  setToken(token) {
    localStorage.setItem("accessToken", "Bearer " + token);
  }
  getToken() {
    return localStorage.getItem("accessToken");
  }

  setAppURL(url) {
    localStorage.setItem("appURL", url);
  }

  getUser(token): Observable<any> {
    const url = `${this._baseURL}/auth/user/info`;

    return this.http
      .get(url, {
        headers: new HttpHeaders({
          Authorization: token,
        }),
      })
      .pipe(map((data) => data));
  }

  aceptarPrivacidad(token): Observable<any> {
    const url = `${this._baseURL}/auth/user/aviso-privacidad`;

    return this.http
      .post(
        url,
        {},
        {
          headers: new HttpHeaders({
            Authorization: token,
          }),
        }
      )
      .pipe(map((data) => data));
  }
  verificarEmail(token) {
    const url = `${this._baseURL}/auth/user/verificar-email`;

    let body = new FormData();
    body.append("token", token);
    return this.http
      .post(url, body, {
        headers: new HttpHeaders({}),
      })
      .pipe(map((data) => data));
  }
  verificarEmailProveedor(token) {
    const url = `${this._baseURL}/auth/provider/verificar-email`;

    let body = new FormData();
    body.append("token", token);
    return this.http
      .post(url, body, {
        headers: new HttpHeaders({}),
      })
      .pipe(map((data) => data));
  }
  checkAvisoPrivacidad(token) {
    const url = `${this._baseURL}/auth/user/aviso-privacidad`;

    return this.http
      .get(url, {
        headers: new HttpHeaders({
          Authorization: token,
        }),
      })
      .pipe(map((data) => data));
  }

  recoveryPassword(body, param): Observable<any> {
    const url = `${this._baseURL}/auth/user/recover/${param}`;
    return this.http
      .post(url, body, {
        headers: new HttpHeaders({}),
      })
      .pipe(map((data) => data));
  }

  recoveryPasswordRequest(body): Observable<any> {
    const url = `${this._baseURL}/auth/user/recover/request`;
    return this.http
      .post(url, body, {
        headers: new HttpHeaders({}),
      })
      .pipe(map((data) => data));
  }

  recoveryPasswordChange(body): Observable<any> {
    const url = `${this._baseURL}/auth/user/recover/password/bienestar`;
    return this.http
      .post(url, body, {
        headers: new HttpHeaders({}),
      })
      .pipe(map((data) => data));
  }

  getNacionalidades(): Observable<any> {
    const url = `${AppConstants.apiBienestar}/catalogos/nacionalidades`;

    return this.http
      .get(url, {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
          Accept: "*/*",
          Autorization: this.getToken()
        }),
      })
      .pipe(map((data) => data));
  }
}
